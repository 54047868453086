import React from 'react';
import { EInvoiceData, EInvoiceItem } from '../types';

export const EInvoiceReportPageView: React.FC<{ invoice: EInvoiceData }> = ({ invoice }) => {
  console.log('render', invoice);
  function getGrossBasedOnNetAndVatPercent(item: EInvoiceItem): any {
    return Math.round(item?.totalNet * (1 + item?.vatPercent / 100 || 0) * 100) / 100;
  }

  const EInvoiceAmount = ({ amount }) => {
    amount = Math.round(amount * 100);
    if (typeof amount !== 'undefined') {
      const wholeEuro = ~~(amount / 100);
      const cents = Math.abs(amount % 100);
      return (
        <span className="amount">
          {safe(wholeEuro)}
          ,
          {safe(cents < 10 ? '0' : '')}
          {safe(cents)}
          &euro;
        </span>
      );
    }
    return (<span className="amount">-,- &euro;</span>);
  };

  const safe = (v: any, def: any = '') => {
    if (typeof v === 'undefined') {
      return def;
    }
    if (v === null) {
      return def;
    }
    return v;
  };

  const EInvoiceHeader = () => (
    <header>
      <img className="logo" src="images/einvoice-logo.png" alt="" />
      <dl className="company-info">
        <dt />
        <dd>
          {safe(invoice?.supplier?.name)}
          {' '}
          {safe(invoice?.supplier?.companyName)}
        </dd>
        <dt>Adresse</dt>
        <dd>
          {safe(invoice?.supplier?.street)}
          <br />
          {safe(invoice?.supplier?.postalCode)}
          {' '}
          {safe(invoice?.supplier?.city)}
          {' '}
          {safe(invoice?.supplier?.country)}
        </dd>
        <dt>Telefon</dt>
        <dd>{safe(invoice?.supplier?.phone)}</dd>
        <dt>Mail</dt>
        <dd>{safe(invoice?.supplier?.mail)}</dd>
      </dl>
    </header>
  );
  const EInvoiceStart = () => (
    <>
      <div className="line">
        <div className="recipient">
          <div className="name1">
            {safe(invoice?.customer?.name)}
            {' '}
            {safe(invoice?.customer?.companyName)}
          </div>
          <div className="street">{safe(invoice?.customer?.street)}</div>
          <div className="city">
            {safe(invoice?.customer?.postalCode)}
            {' '}
            {safe(invoice?.customer?.city)}
          </div>
          <div className="country">{safe(invoice?.customer?.country)}</div>
        </div>
        <dl className="invoice-details">
          <dt>Rechnungsnummer</dt>
          <dd>{safe(invoice?.number)}</dd>
          <dt>Rechnungsdatum</dt>
          <dd>{safe(invoice?.invoiceDate)}</dd>
        </dl>
      </div>
      <div className="invoice-message">
        <h1>{safe(invoice?.message?.title || `E-Rechnung ${safe(invoice?.number)}`)}</h1>
        <p>{safe(invoice?.message?.details || 'Die Rechnungsdaten aus dem E-/X-Rechnung Datei')}</p>
      </div>
    </>
  );

  const EInvoiceSummary = () => (
    <table className="invoice-summary">
      <thead>
        <tr>
          <th>
            Elemente
          </th>
          <th>
            Einheitspreis
          </th>
          <th>
            Menge
          </th>
          <th>
            USt.
          </th>
          <th>
            Neto
          </th>
          <th>
            Brutto
          </th>
        </tr>
      </thead>
      <tbody>
        {invoice?.items?.map(item => (
          <tr key={JSON.stringify(item)}>
            <td>
              {safe(item?.name)}
            </td>
            <td className="amount-wrapper">
              <EInvoiceAmount amount={item?.unitPrice} />
            </td>
            <td className="amount-wrapper">
              {safe(item?.count)}
            </td>
            <td className="amount-wrapper">
              {safe(item?.vatPercent, 0)}
              %
            </td>
            <td className="amount-wrapper">
              <EInvoiceAmount amount={item?.totalNet} />
            </td>
            <td className="amount-wrapper">
              <EInvoiceAmount amount={safe(item?.totalGross, getGrossBasedOnNetAndVatPercent(item))} />
            </td>

          </tr>
        ))}

        {invoice?.totalDiscount ? (
          <tr>
            <td colSpan={3} />
            <td colSpan={1}>
              Rabatt
            </td>
            <td className="amount-wrapper">
              {/* assume it's net */''}
              <EInvoiceAmount amount={-invoice?.totalDiscount} />
            </td>
            <td />
          </tr>
        ) : ''}
        <tr>
          <td colSpan={3} />
          <td>
            <p><b>Summe</b></p>
          </td>
          <td className="amount-wrapper">
            <b><EInvoiceAmount amount={invoice?.totalNet} /></b>
          </td>
          <td className="amount-wrapper">
            <b><EInvoiceAmount amount={invoice?.totalGross} /></b>
          </td>
        </tr>
        <tr>
          <td colSpan={4} />
          <td>
            Davon USt.
          </td>
          <td className="amount-wrapper">
            <EInvoiceAmount amount={invoice?.totalTax} />
          </td>
        </tr>
        {invoice?.totalPrepaid ? (
          <tr>
            <td colSpan={4} />
            <td>
              Vorauszahlungen
            </td>
            <td className="amount-wrapper">
              <EInvoiceAmount amount={-invoice?.totalPrepaid} />
            </td>
          </tr>
        ) : ''}
      </tbody>
      <tfoot>
        <tr>
          <td colSpan={4} />
          <td>
            Zu zahlende Betrag
          </td>
          <td className="amount-wrapper">
            <EInvoiceAmount amount={invoice?.totalPayable} />
          </td>
        </tr>
      </tfoot>
    </table>
  );
  document.title = `IP_${safe(invoice.number)}_Rechnung_${safe(invoice?.customer?.companyName)}_${safe(invoice?.totalGross / 100)}EUR`;
  return (
    <>
      <EInvoiceHeader />
      <EInvoiceStart />
      <EInvoiceSummary />
      <div className="invoice-order">
        <p>
          <strong>
            {invoice?.directDebit
              ? <>Bitte beachten Sie, dass der Rechnungsbetrag entsprechend Ihres Lastschriftmandats von Ihrem Bankkonto abgebucht wird.</>
              : (
                <>
                  Bitte &uuml;berweisen Sie den oben genannten Betrag auf das Konto
                  {invoice?.supplier?.bankaccount?.accountHolder || invoice?.supplier?.companyName}
                  {invoice?.supplier?.bankaccount?.iban}
                </>
              )
            }
          </strong>
        </p>
      </div>
    </>
  );
};
