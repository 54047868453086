import React, { useContext, useEffect, useState } from 'react';
import { Col, Modal, Row } from 'antd';
import Icon from '@ant-design/icons';
import { SPACE_SIZES } from 'lib/constants';
import { PropertyLegacyDtoAdministrationTypeEnum } from 'api/accounting';
import ModalWithSubtitle from 'storybook-components/modals/ModalWithSubtitle/ModalWithSubtitle';
import { LanguageContext } from '../../../../contexts/LanguageContext';
import { translations } from '../../translations';
import SelectInput, { SelectOption } from '../../../../elements/Inputs/SelectInput/SelectInput';
import Button from '../../../../elements/Buttons/Button/Button';
import { ICONS } from '../../../../components/icons';
import './DKCopyModal.scss';
import { formatDate } from '../../../../lib/Utils';

interface ModalProps {
    visible: boolean,
    onClickCancel: () => void,
    onClickOk: (administrationType: PropertyLegacyDtoAdministrationTypeEnum, sourceYear: number, destinationYear: number) => void
    sourceYears: SelectOption[],
    selectedSourceYear: number,
    economicYearStart: Date,
    economicYearEnd: Date,
    selectedProperty: any,
}

export function DKCopyModal({
  visible, onClickCancel, onClickOk, sourceYears, selectedSourceYear, economicYearStart, economicYearEnd, selectedProperty,
}: ModalProps) {
  const { tl } = useContext(LanguageContext);
  const [sourceYear, setSourceYear] = useState<number>(selectedSourceYear);
  const [destinationYear, setDestinationYear] = useState<number>(selectedSourceYear + 1);
  const subtitleIsDisplayed = selectedProperty.data ? (selectedProperty.data.administrationType === 'WEG') : false;

  function computeDestinationYears() {
    const tempOptionElements: any[] = [];
    for (let i = sourceYear - 5; i <= sourceYear + 5; i += 1) {
      const yearStart = new Date(economicYearStart);
      const yearEnd = new Date(economicYearEnd);
      yearStart.setFullYear(i);
      yearEnd.setFullYear(i);
      if (yearEnd < yearStart) {
        yearEnd.setFullYear(i + 1);
      }
      tempOptionElements.push({ label: `${formatDate(yearStart)} — ${formatDate(yearEnd)}`, value: i });
    }
    return tempOptionElements;
  }

  useEffect(() => {
    setSourceYear(selectedSourceYear);
    setDestinationYear(selectedSourceYear + 1);
  }, [selectedSourceYear]);

  return (
    <ModalWithSubtitle
      className="DKCopyModal"
      title={tl(translations.propertyDistributionKey.copyModal.title)}
      subtitle={subtitleIsDisplayed && tl(translations.propertyDistributionKey.copyModal.subtitle)}
      visible={visible}
      onCancel={onClickCancel}
      footer={(
        <div className="footerWrapper">
          <div className="warningMessage">
            <Icon component={ICONS.yellowInfo} />
            <span>{tl(translations.propertyDistributionKey.copyModal.warningMessage)}</span>
          </div>
          <Button
            type="primary"
            disabled={sourceYear === destinationYear}
            onClick={() => {
              onClickOk(selectedProperty.data?.administrationType, sourceYear, destinationYear);
              onClickCancel();
            }}
          >
            {tl(translations.propertyDistributionKey.copyModal.button)}
          </Button>
        </div>
      )}
    >
      <Row gutter={[0, SPACE_SIZES.large]}>
        <Col span={24}>
          <SelectInput
            showSearch
            value={sourceYear}
            onChange={val => setSourceYear(val)}
            placeholder={tl(translations.propertyDistributionKey.copyModal.source)}
            label={tl(translations.propertyDistributionKey.copyModal.source)}
            options={sourceYears}
          />
        </Col>
        <Col span={24}>
          <SelectInput
            showSearch
            value={destinationYear}
            onChange={val => setDestinationYear(val)}
            placeholder={tl(translations.propertyDistributionKey.copyModal.destination)}
            label={tl(translations.propertyDistributionKey.copyModal.destination)}
            options={computeDestinationYears()}
          />
        </Col>
      </Row>
    </ModalWithSubtitle>
  );
}
